@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .input {
        @apply mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
        invalid:border-pink-500 invalid:text-pink-600
        focus:invalid:border-pink-500 focus:invalid:ring-pink-500;
    }
    .btn-primary {
        @apply bg-pink-50 hover:bg-pink-100 border-black radix-state-open:border-2 px-3 py-1 mx-1;
    }
    .btn-secondary {
        @apply bg-blue-500 hover:bg-sky-700 border-black radix-state-open:border-2 px-3 py-1 mx-1;
    }
    .btn-big {
        @apply rounded-full  px-6 py-2 text-base font-bold text-white drop-shadow-lg hover:scale-95 hover:opacity-70 hover:transition-all;
    }
    .btn-small {
        @apply rounded-lg px-4 py-2 text-xs font-medium text-white drop-shadow-sm hover:opacity-80 hover:transition-all;
    }

    .new-message {
        @apply p-4 m-5 bg-white;
        border: 5px dotted #f4978e;
    }
}
