:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 200, 200, 200;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  /*background: linear-gradient(*/
  /*    to bottom,*/
  /*    transparent,*/
  /*    rgb(var(--background-end-rgb))*/
  /*  )*/
  /*  rgb(var(--background-start-rgb));*/
  /*border:5px double #ffffff;background-color:#f8edeb;box-shadow:0 0 0 6px #f8edeb;padding:16px;margin:20px;*/
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}



.CheckboxRoot {
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px var(--blackA7);
}
.CheckboxRoot:hover {
  background-color: var(--violet3);
}
.CheckboxRoot:focus {
  box-shadow: 0 0 0 2px black;
}

.CheckboxIndicator {
  color: var(--violet11);
}

.Label {
  padding-left: 15px;
  font-size: 15px;
  line-height: 1;
}


@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100&display=swap');

/*html {*/
/*  width: 100%;*/
/*  height: 100vh;*/
/*  !*overflow: hidden;*!*/
/*  background: linear-gradient(#00bfff, #fff);*/
/*}*/

.sakura {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sakura li{
  position: absolute;
  list-style: none;
  top: -50px;
  background: #ffdbed;
  border-radius: 0% 70%;
  animation: fall 4s linear infinite, sway 2s ease-in-out infinite alternate;
}


@keyframes fall {
  to {
    top: 120%;
  }
}

@keyframes sway1 {
  from {
    transform: translateX(0px) rotate(0deg);
  }
  to {
    transform: translateX(200px) rotate(-45deg);
  }
}

@keyframes sway2 {
  from {
    transform: translateX(200px) rotate(-45deg);
  }
  to {
    transform: translateX(0px) rotate(0deg);
  }
}

.sakura li:nth-child(1){
  left: 0%;
  width: 24px;
  height: 15px;
  animation: fall 10s linear infinite,
  sway1 3s ease-in-out infinite alternate;
  animation-delay: 2s;
}

.sakura li:nth-child(2){
  left: 5%;
  width: 13px;
  height: 9px;
  animation: fall 15s linear infinite,
  sway1 2s ease-in-out infinite alternate;
  animation-delay: 8s;
}

.sakura li:nth-child(3){
  left: 15%;
  width: 16px;
  height: 10px;
  animation: fall 9s linear infinite,
  sway1 3.5s ease-in-out infinite alternate;
  animation-delay: 13s;
}

.sakura li:nth-child(4){
  left: 30%;
  width: 16px;
  height: 10px;
  animation: fall 8s linear infinite,
  sway2 4s ease-in-out infinite alternate;
  animation-delay: 7s;
}
.sakura li:nth-child(5){
  left: 40%;
  width: 16px;
  height: 10px;
  animation: fall 10s linear infinite,
  sway1 4s ease-in-out infinite alternate;
  animation-delay: 0s;
}
.sakura li:nth-child(6){
  left: 55%;
  width: 24px;
  height: 15px;
  animation: fall 11s linear infinite,
  sway2 3s ease-in-out infinite alternate;
  animation-delay: 3s;
}
.sakura li:nth-child(7){
  left: 65%;
  width: 16px;
  height: 10px;
  animation: fall 7s linear infinite,
  sway2 3.5s ease-in-out infinite alternate;
  animation-delay: 7s;
}
.sakura li:nth-child(8){
  left: 50%;
  width: 13px;
  height: 9px;
  animation: fall 7s linear infinite,
  sway1 3s ease-in-out infinite alternate;
  animation-delay: 3s;
}
.sakura li:nth-child(9){
  left: 80%;
  width: 16px;
  height: 10px;
  animation: fall 10s linear infinite,
  sway2 4s ease-in-out infinite alternate;
  animation-delay: 4s;
}

.card {
  border:5px double #ffffff;background-color:#f8edeb;box-shadow:0 0 0 6px #f8edeb;padding:16px;margin:20px;
}


.flowing {
  margin: 0;
  font-size: 22px;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  /*font-weight: bold;*/
  /*white-space: nowrap;*/
  animation: flowing-anim 8s;
}

@keyframes flowing-anim {
  0%{
    width: 0%;
  }
  100%{
    width: 100%;
  }
}

.microphone-button {
  -webkit-touch-callout:none; /* リンク長押しのポップアップを無効化 */
  -webkit-user-select:none; /* テキスト長押しの選択ボックスを無効化 */
}
